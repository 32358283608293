import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

//Styles
import '../../../index.css';
import '../css/public.css';

//Assets
import vsdvLogo from '../../../assets/images/logo.svg';
import svzLogo from '../../../assets/images/svz-logo-red.svg';
import vsdvArtwork from '../../../assets/images/logo-detail.svg';

const Maintenance = () => {

    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');

    useEffect(() => {
       
    }, []);

    return (
        <div id="maintenance">
            <div className="maintenance-content">
                <img src={svzLogo} className="logo" />
                <p>Er wordt momenteel gepland onderhoud gepleegd. Probeer het later nog eens.</p>
            </div>
        </div>
      );
  
};
export default Maintenance;
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess } from '../../../settings/Helpers';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import { Tooltip } from 'react-tooltip';
import Rating from 'react-rating';
import { ThreeDots } from  'react-loader-spinner';

//API
import api from '../../../settings/AxiosSetup';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';

//styles
import './enquete.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-tooltip/dist/react-tooltip.css';

const Enquete = () => {

    //Set variables
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const [medewerker, setMedewerker] = useState(JSON.parse(localStorage.getItem('medewerker')));
    const [isLoading, setIsLoading] = useState(true);
    const [title, setTitle] = useState('');
    const [intro, setIntro] = useState('');
    const [questions, setQuestions] = useState([
        // {
        //     vraag: 'Dit is de tekst van vraag 1',
        //     tooltip: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        //     rating: 0,
        //     valid: true
        // },
        // {
        //     vraag: 'Dit is de tekst van vraag 2',
        //     tooltip: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        //     rating: 0,
        //     valid: true
        // },
        // {
        //     vraag: 'Dit is de tekst van vraag 3',
        //     tooltip: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        //     rating: 0,
        //     valid: true
        // },
        // {
        //     vraag: 'Dit is de tekst van vraag 4',
        //     tooltip: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        //     rating: 0,
        //     valid: true
        // },
        // {
        //     vraag: 'Dit is de tekst van vraag 5',
        //     tooltip: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        //     rating: 0,
        //     valid: true
        // }
    ]);
    const [valid, setValid] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const setQuestionRating = (rate, index) => {
        const current = [...questions];
        current[index].rating = rate;
        current[index].valid = true;
        setQuestions(current);
    }

    const submitEnquete = async () => {
        setIsSubmitting(true);
        try {
            const response = await api.post('/feedbacks', {
                data: questions
            });
            if (response.data.id) {
                navigate('/bedankt-enquete');
            }
        } catch (error) {
            console.log(error);
        }
    }

    const validateEnquete = () => {
        const current = [...questions];
        const isValid = questions.some(question => question.rating === 0);
        if (isValid) {
            setValid(false);
            current.map((item, index) =>  {
                if (item.rating === 0) {
                    item.valid = false;
                } else {
                    item.valid = true;
                }
            })
        } else {
            setValid(true);
            current.map((item, index) =>  {
                item.valid = true;
            })
            submitEnquete();
        }
        setQuestions(current);
    }

    const getQuestions = async () => {
        try {
            const response = await api.get('/enquetes');
            if (response.data.data) {
                const arrayObjects = [];
                response.data.data.map((item, index) =>  {
                    const obj = {
                        id: item.id,
                        vraag: item.attributes.vraag,
                        tooltip: item.attributes.tooltip,
                        rating: 0,
                        valid: true
                    };
                    arrayObjects.push(obj);
                })
                setQuestions(arrayObjects);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
        
    }
    
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
            setTitle(result.data.attributes.feedback_titel);
            setIntro(result.data.attributes.feedback_intro);
        });
        getQuestions();
    }, []);
    
    return (
    <div id="main">
        <div className="enquete">
            <div className="enquete-content">
                {
                    isLoading ?
                        <div className="loading">
                            <ThreeDots 
                                height="80" 
                                width="80" 
                                radius="9"
                                color="#001c43" 
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    :
                    <>
                        <div className="enquete-header">
                            <h1>{title}</h1>
                            <p>{intro}</p>
                            <span>*U kunt de vraag beantwoorden d.m.v. een rating waarbij 1 ster staat voor niet goed en 5 voor uitstekend.</span>
                        </div>
                        <div className="questions">
                            {
                                questions.map((item, index) =>  {
                                    return  <div key={index} className={'question ' + (!item.valid ? 'invalid' : '')}>
                                                <div className="question-contents">
                                                    <p>{item.vraag}</p>
                                                    {
                                                        item.tooltip ?
                                                        <>
                                                            <Icon.InfoCircle id={'tooltip-' + index} />
                                                            <Tooltip
                                                            anchorId={'tooltip-' + index}
                                                            place="top"
                                                            content={item.tooltip}
                                                            style={{ 
                                                                backgroundColor: "rgba(255, 255, 255, 1.0)", 
                                                                color: "#001c43", 
                                                                opacity: 1.0, 
                                                                maxWidth: 250, 
                                                                fontFamily: 'PrometoLight', 
                                                                fontSize: 14, 
                                                                lineHeight: 1.2,
                                                                boxShadow: '0 5px 5px rgba(0,0,0,0.05), 0 5px 5px rgba(0,0,0,0.05)' }}
                                                            />
                                                        </>    
                                                        :
                                                        null
                                                    }
                                                </div>
                                                <Rating 
                                                    initialRating={item.rating}
                                                    emptySymbol={<Icon.Star color={item.valid ? '#001E4E' : '#CB0A32'} size={24} />}
                                                    fullSymbol={<Icon.StarFill color="#F2CC00" size={24} />}
                                                    onChange={(rate) => setQuestionRating(rate, index)}
                                                />
                                            </div>
                                })
                            }
                        </div>
                        <div className="enquete-footer">
                            {!valid ? <p className="error">Nog niet alle vragen zijn beantwoord</p> : null}
                            <button id="submit-results" onClick={validateEnquete}>Resultaten insturen</button>
                        </div>
                    </>
                }
            </div>
            {/* <img src={vsdvArtwork} className="artwork" /> */}
            <div className={'loader ' + (isSubmitting ? 'show' : '')}>
                <ThreeDots 
                    height="80" 
                    width="80" 
                    radius="9"
                    color="#001c43" 
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
                <p>Enquete aan het insturen...</p>
            </div>
        </div>
    </div>
    );
};
export default Enquete;
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import Lottie from "lottie-react";

//Styles
import '../../../index.css';
import './offerte-bedankt.css';

//Assets
import vsdvLogo from '../../../assets/images/logo.svg';
import vsdvArtwork from '../../../assets/images/logo-detail.svg';
import confetti from "../../../assets/animations/confetti.json";

const BedanktOfferte = () => {

    const navigate = useNavigate();
    const { status } = useParams();
    const environment = process.env.REACT_APP_APP_URL;
    const lottieAnimation = useRef();

    useEffect(() => {
        
    }, []);

    return (
        <div id="thanks-offerte">
            <div className="content">
                <div className="container">
                    {
                        status === 'afgewezen' ?
                        null
                        :
                        <Lottie lottieRef={lottieAnimation} loop={false} autoplay={true} animationData={confetti} className="confetti" style={{position:'absolute',zIndex: 0,top:0,left:0}} />
                    }
                    {
                        status === 'afgewezen' ?
                        <div className="thanks-content">
                            <h1>We hebben de afwijzing ontvangen</h1>
                            <p>We hebben uw afwijzing in goede orde ontvangen. U kunt de pagina nu sluiten.</p>
                        </div>
                        :
                        <div className="thanks-content">
                            <h1>Bedankt voor het ondertekenen!</h1>
                            <p>U ontvangt nog een kopie van de getekende versie per mail. U kunt de pagina nu sluiten.</p>
                        </div>
                    }
                    
                </div>
            </div>
            {/* <img src={vsdvArtwork} className="artwork" /> */}
        </div>
      );
  
};
export default BedanktOfferte;
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";

//Helper functions
import { getSettings } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import toast, { Toaster } from 'react-hot-toast';
import { TailSpin } from  'react-loader-spinner';
import SignatureCanvas from 'react-signature-canvas';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';

//styles
import './instellingen.css';

const Instellingen = () => {

    //Set variables
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const rolesSignature = ['authenticated', 'super_admin'];
    const environment = process.env.REACT_APP_APP_URL;
    const [settings, setSettings] = useState([]);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [changingSignature, setChangingSignature] = useState(false);
    const [signature, setSignature] = useState('');
    const signatureRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    //Initialize notify for error and success
    const notifyError = (text) => toast.error(text, {
        position: "top-center"
    });
    const notifySuccess = (text) => toast.success(text, {
        position: "top-center"
    });

    //Function to update password
    const handlePassword = async (event) => {
        event.preventDefault();
        if (!currentPassword) {
            notifyError('Vul je huidige wachtwoord in.');
            return;
        }
        if (!newPassword) {
            notifyError('Vul een nieuw wachtwoord in.');
            return;
        }
        if (!confirmNewPassword) {
            notifyError('Bevestig het nieuwe wachtwoord.');
            return;
        }
        if (newPassword !== confirmNewPassword) {
            notifyError('De wachtwoorden komen niet overeen.');
            return;
        }
        if (currentPassword === newPassword) {
            notifyError('Je nieuwe wachtwoord mag niet hetzelfde zijn als je huidige wachtwoord.');
            return;
        }
        setIsLoading(true);
        try {
            const response = await api.post('/auth/change-password', {
                "currentPassword": currentPassword,
                "password": newPassword,
                "passwordConfirmation": confirmNewPassword
            });
            if (response.data) {
                setCurrentPassword('');
                setNewPassword('');
                setConfirmNewPassword('');
                notifySuccess('Je wachtwoord is bijgewerkt en opgeslagen!');
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    if (error.response.data.error.message.includes("invalid")) {
                        notifyError('Het ingevulde huidige wachtwoord is ongeldig');
                    }
                    else if (error.response.data.error.message.includes("least")) {
                        notifyError('Je nieuwe wachtwoord moet minstens 6 tekens bevatten');
                    }
                    else {
                        notifyError(error.response.data.error.message);
                    }
                    
            }
        }
    }

    const setSignatureToMedewerker = async (data) => {
        try {
            const response = await api.put('/medewerkers/' + medewerker.id, {
                "data": { 
                    "handtekening": data.id
                }
            });
            if (response.data) {
                setSignature(environment + data.url);
                setChangingSignature(false);
                notifySuccess('Handtekening bijgewerkt en opgeslagen');
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                    break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    //Function to upload signature and save to medewerker
    const saveSignature = async () => {
        const canvas = signatureRef.current.getCanvas();
        const base64Image = canvas.toDataURL(); // This will give you the base64 image data
        const binaryData = atob(base64Image.split(',')[1]);
        const length = binaryData.length;
        const uint8Array = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
        }

        var formData = new FormData();
        const blob = new Blob([uint8Array], { type: 'image/png' });
        formData.append('files', blob, 'signature.png');

        setIsLoading(true);
        try {
            const response = await api.post('/upload', formData, {
                headers: { 
                    "Content-Type": "multipart/form-data"
                }
            });
            if (response.data) {
                setSignatureToMedewerker(response.data[0]);
            }
        } catch (error) {
            setIsLoading(false);
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                    break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    const getMedewerker = async () => {
        try {
            const response = await api.get('/medewerkers/me');
            if (response.data) {
                if (response.data.handtekening) {
                    setSignature(environment + response.data.handtekening.url);
                }
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    //Check if user is authenticated, else redirect to login
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            setSettings(result);
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        getMedewerker();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        {
            medewerker ?
            <SideNav page="/instellingen" />
            :
            null
        }
        
        <div className="dashboard-content">
            <DashboardHeader title="Instellingen" />
            <div className="blocks">
                <div className="block">
                    <div className="content">
                    <div className="block-header"><h3>Wachtwoord wijzigen</h3></div>
                    <form id="change-password" className="password-form" onSubmit={handlePassword}>
                        <input type="password" name="current_password" placeholder="Huidig wachtwoord" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} className="form-field" disabled={isLoading} />
                        <input type="password" name="new_password" placeholder="Nieuw wachtwoord" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} className="form-field" disabled={isLoading} />
                        <input type="password" name="confirm_new_password" placeholder="Nieuw wachtwoord herhalen" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} className="form-field" disabled={isLoading} />
                        <div className="form-footer">
                        <input type="submit" value="Bijwerken" className="submit-form" disabled={isLoading} />
                        {
                            isLoading ?
                            <TailSpin
                                height="30"
                                width="30"
                                color="#001c43"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />
                            :
                            null
                        }
                        </div>
                    </form>
                    </div>
                </div>

                {
                    rolesSignature.includes(medewerker.gebruiker.role.type) ?
                    <div className="block signature-block">
                        <div className="content">
                            <div className="block-header"><h3>Handtekening</h3>
                            {
                                changingSignature ?
                                <div className="actions">
                                <button className="cancel" onClick={() => setChangingSignature(false)}>Annuleren</button>
                                <button className="save" onClick={saveSignature}>Opslaan</button>
                                </div>
                                :
                                <button className="change" onClick={() => setChangingSignature(true)}>Wijzigen</button>
                            }
                            </div>
                            {
                                changingSignature ?
                                <div className="canvas-container">
                                <SignatureCanvas ref={signatureRef} penColor='black' backgroundColor='white' canvasProps={{className: 'canvas'}} />
                                </div>
                                :
                                <>
                                {
                                    signature ?
                                    <img className="signature-image" src={signature} alt="Signature" />
                                    :
                                    <p>Er is nog geen handtekening ingesteld</p>
                                }
                                </>
                            }
                            
                            
                        </div>
                    </div>
                    :
                    null
                }
                

            </div>
            <Toaster />
        </div>
        {
            medewerker ?
            <BottomNav page="/instellingen" />
            :
            null
        }
    {/* <img src={vsdvArtwork} className="artwork" />     */}
    </div>
    );
};
export default Instellingen;
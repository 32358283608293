import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

//Helper functions
import { getSettings, addManualLog, notifyError, notifySuccess, downloadExcelLTL, downloadExcelFTL, handleDownload, getFileContents } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import toast, { Toaster } from 'react-hot-toast';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import DataTable from 'react-data-table-component';
import { saveAs } from "file-saver";
import Rodal from 'rodal';
import { TailSpin } from 'react-loader-spinner';
import PulseDot from 'react-pulse-dot';
import { Tooltip } from 'react-tooltip';
import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import Lottie from "lottie-react";
import exportFromJSON from 'export-from-json';
import { exportToExcel } from 'react-json-to-excel';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';
import OfferteDocument from '../../../components/offerte/offerte-document';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';
import creatingOfferteAnimation from "../../../assets/animations/create-dossier.json";

//styles
import './offertes.css';
import 'rodal/lib/rodal.css';
import 'react-pulse-dot/dist/index.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-tooltip/dist/react-tooltip.css';


const Datum = (props) => <div><span className="sort sort-date">{props.sortDate}</span><p>{props.datum}</p></div>
const Actions = (props) => {
    const signedIndex = props.offerte.versies.findIndex(obj => obj.getekend === true);
    const lastSignedIndex = props.offerte.versies.reduce((acc, current, index) => {
        if (current.getekend) {
            return index;
        } else {
            return acc;
        }
    }, -1);
    const fileUrl = props.offerte.versies[lastSignedIndex].bestand ? props.environment + '' + props.offerte.versies[lastSignedIndex].bestand.url : '';
    const fileName = props.offerte.versies[lastSignedIndex].bestand ? props.offerte.versies[lastSignedIndex].bestand.name : '';
    var fileNameFs = props.offerte.versies[lastSignedIndex].pad;
    return (
        <div className="actions">
            {/* {
                fileName ?
                <>
                    <span className="action-icon download" id={'download-' + props.id} onClick={() => { saveAs(fileUrl, fileName) }}><Icon.Download /></span>
                    <Tooltip
                        anchorId={'download-' + props.id}
                        place="top"
                        content="Download getekende offerte"
                    />
                </>
                :
                null
            } */}
            {
                fileNameFs ?
                <>
                    <span className="action-icon download" id={'downloadd-' + props.id} onClick={() => { props.handleDownload(props.offerte.offertenummer, 'leeg', fileNameFs) }}><Icon.Download /></span>
                    <Tooltip
                        anchorId={'downloadd-' + props.id}
                        place="top"
                        content="Download getekende offerte"
                    />
                </>
                :
                null
            }
            {
                props.offerte.ltl && props.medewerker.gebruiker.role.type === 'tariefinrichting' || props.offerte.twee_ltl && props.medewerker.gebruiker.role.type === 'tariefinrichting' ?
                <>
                    <span className="action-icon" id={'excel-' + props.id} onClick={() => { props.downloadExcelLTL(props.offerte, false) }}><Icon.FileEarmarkSpreadsheet /></span>
                    <Tooltip
                        anchorId={'excel-' + props.id}
                        place="top"
                        content="Download prijslijst LTL"
                    />
                </>
                :
                null
            }
            {
                props.offerte.twee_ltl && props.medewerker.gebruiker.role.type === 'tariefinrichting' ?
                <>
                    <span className="action-icon" id={'excel-twee-ltl-' + props.id} onClick={() => { props.downloadExcelLTL(props.offerte, true) }}><Icon.FileEarmarkSpreadsheet /></span>
                    <Tooltip
                        anchorId={'excel-twee-ltl-' + props.id}
                        place="top"
                        content="Download prijslijst 2 LTL"
                    />
                </>
                :
                null
            }
            {
                props.offerte.ftl && props.medewerker.gebruiker.role.type === 'tariefinrichting' ?
                <>
                    <span className="action-icon" id={'excel-ftl-' + props.id} onClick={() => { props.downloadExcelFTL(props.offerte) }}><Icon.FileEarmarkSpreadsheet /></span>
                    <Tooltip
                        anchorId={'excel-ftl-' + props.id}
                        place="top"
                        content="Download prijslijst FTL"
                    />
                </>
                :
                null
            }
            {
                props.medewerker.gebruiker.role.type === 'tariefinrichting' ?
                <>
                    <span className="action-icon logs" id={'logs-' + props.id} onClick={() => { 
                        props.setOfferte(props.offerte); 
                        props.setShowStats(true); 
                        props.setStats(props.offerte.statistieken); 
                        props.setLogs(props.offerte.logs); 
                        props.setOfferteVersies(props.offerte.versies);
                        props.setSignedVersieIndex(lastSignedIndex); }}><Icon.BarChart /></span>
                    <Tooltip
                        anchorId={'logs-' + props.id}
                        place="top"
                        content="Toon statistieken"
                    />
                </>
                :
                null
            }
            {
                props.medewerker.gebruiker.role.type === 'authenticated' ?
                <>
                    <span className="action-icon revenue" id={'revenue-' + props.id} onClick={() => { props.setOfferteToChange(props.offerte); props.setRevenue(props.offerte.omzetschatting); props.setShowModalRevenue(true); }}><Icon.Tag /></span>
                    <Tooltip
                        anchorId={'revenue-' + props.id}
                        place="top"
                        content="Wijzig omzetschatting"
                    />
                </>
                :
                null
            }
            <>
                <span className="action-icon task-done" id={'task-done-' + props.id} style={{color:'#00D100'}} onClick={() => {
                    if (props.medewerker.gebruiker.role.type === 'debiteuren' && !props.offerte.klant.accountnummer) {
                        props.setShowModalDebiteuren(true);
                        props.setOfferteToChange(props.offerte);
                    } else if (props.medewerker.gebruiker.role.type === 'tariefinrichting') {
                        props.updateStatusWorkflow(props.offerte, props.offerte.klant.nieuwe_klant ? 'new': 'existing');
                    } else if (props.medewerker.gebruiker.role.type === 'sales' || props.medewerker.gebruiker.role.type === 'authenticated') {
                        props.setShowModalPortal(true);
                        props.setOfferteToChange(props.offerte);
                    } else {
                        props.updateStatusWorkflow(props.offerte);
                    }
                }}><Icon.CheckCircleFill /></span>
                <Tooltip
                    anchorId={'task-done-' + props.id}
                    place="top"
                    content={props.tooltipText}
                />
            </>
        </div>
    )
};

const TeVerwerkenOffertes = () => {

    //Set variables
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchValue = searchParams.get('search');
    const loggedIn = localStorage.getItem('ingelogd');
    const [medewerker, setMedewerker] = useState(JSON.parse(localStorage.getItem('medewerker')));
    const environment = process.env.REACT_APP_APP_URL;
    const [loadingOffertes, setLoadingOffertes] = useState(true);
    const [loaderMessage, setLoaderMessage]  = useState('');
    const [showModalDebiteuren, setShowModalDebiteuren] = useState(false);
    const [debiteurnummer, setDebiteurnummer] = useState('');
    const [showModalPortal, setShowModalPortal] = useState('');
    const [userNamePortal, setUserNamePortal] = useState('');
    const [passwordPortal, setPasswordPortal] = useState('');
    const [linkPortal, setLinkPortal] = useState('');
    const [bijlagen, setBijlagen] = useState([]);
    const [bijlagenMail, setBijlagenMail] = useState([]);
    const [offertes, setOffertes] = useState([]);
    const [offerte, setOfferte] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [showStats, setShowStats] = useState(false);
    const [workflow, setWorkflow] = useState([]);
    const [stats, setStats] = useState([]);
    const [logs, setLogs] = useState([]);
    const [offerteVersies, setOfferteVersies] = useState([]);
    const [signedVersieIndex, setSignedVersieIndex] = useState('');
    const [manualLog, setManualLog] = useState('');
    const [addingLog, setAddingLog] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [offerteToChange, setOfferteToChange] = useState('');
    const [offerteToDuplicate, setOfferteToDuplicate] = useState('');
    const [savingOfferte, setSavingOfferte] = useState(false);
    const [revenue, setRevenue] = useState('');
    const [showModalRevenue, setShowModalRevenue] = useState(false);
    const lottieAnimation = useRef();

    const delay = ms => new Promise(res => setTimeout(res, ms));

    if (medewerker.gebruiker.role.type === 'debiteuren') {
        var tooltipText = 'Debiteuren zaken allemaal ingericht';
        var updatedStatus = 'Debiteurenbeheer heeft alles ingevoerd';
        var updatedLog = 'Debiteurenzaken gedaan door ' + medewerker.voornaam + ' ' + medewerker.achternaam;
        var newStatus = 'tariefinrichting';
    } else if (medewerker.gebruiker.role.type === 'tariefinrichting') {
        var tooltipText = 'Tarieven zijn ingevoerd';
        var updatedStatus = 'Tariefinrichting heeft alle tarieven ingevoerd';
        var updatedLog = 'Tarieven zijn ingevoerd door ' + medewerker.voornaam + ' ' + medewerker.achternaam;
        var newStatus = 'sales';
    } else if (medewerker.gebruiker.role.type === 'sales' || medewerker.gebruiker.role.type === 'authenticated' || medewerker.gebruiker.role.type === 'super_admin') {
        var tooltipText = 'Accounts in portals etc zijn aangemaakt';
        var updatedStatus = 'Sales heeft alle accounts in portals etc toegevoegd en mail verstuurd naar klant';
        var updatedLog = 'Sales taken gedaan door ' + medewerker.voornaam + ' ' + medewerker.achternaam;
        var newStatus = 'marketing';
    } else {
        var tooltipText = 'Presentje etc naar klant verstuurd';
        var updatedStatus = 'Marketing heeft presentje naar klant gestuurd';
        var updatedLog = 'Marketing taken gedaan door ' + medewerker.voornaam + ' ' + medewerker.achternaam;
        var newStatus = 'afgerond';
    }

    //Function to sort on string value
    const filteredData = offertes.filter((item) => {
        const columnsToSearch = ['offerte', 'klantnaam', 'accountnummer', 'divisie', 'type', 'vestiging', 'datum'];
        for (let i = 0; i < columnsToSearch.length; i++) {
          const column = columnsToSearch[i];
          if (column === 'datum') {
            var cellData = item[column].props.datum.toLowerCase();
          } else {
            var cellData = item[column].toString().toLowerCase();
          }
          if (cellData.includes(filterText.toLowerCase())) {
            return true; // Return true if any column matches the search text
          }
        }
        return false; // Return false if no columns match the search text
    });

    //Function to do custom sort on timestamp instead of datestring
    const customDateSort = (rowA, rowB) => {
        const a = rowA.datum.props.sortDate;
        const timeStampA = new Date(a).getTime();
        const b = rowB.datum.props.sortDate;
        const timeStampB = new Date(b).getTime();
        if (timeStampA > timeStampB) {
            return 1;
        }
        if (timeStampB > timeStampA) {
            return -1;
        }
        return 0;
    };

    const changeBijlagen = (e) => {
        var current = [...bijlagenMail];
        if (e.target.checked) {
            const obj = {
                fileName: e.target.value,
                path: environment + '' + e.target.dataset.url
            };
            current.push(obj);
        } else {
            current = current.filter(item => item.fileName !== e.target.value);
        }
        setBijlagenMail(current);
    }

    //Columns for datatable
    const columns = [
        {
            name: 'Offerte',
            selector: row => row.offerte,
            sortable: true
        },
        {
            name: 'Klantnaam',
            selector: row => row.klantnaam,
            sortable: true
        },
        {
            name: 'Accountnummer',
            selector: row => row.accountnummer,
            sortable: true
        },
        {
            name: 'Divisie',
            selector: row => row.divisie,
            sortable: true
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true
        },
        {
            name: 'Vestiging',
            selector: row => row.vestiging,
            sortable: true
        },
        {
            name: 'Datum',
            selector: row => row.datum,
            sortable: true
        },
        {
            name: 'Acties',
            selector: row => row.acties,
            minWidth: "280px"
        },
    ];
    //Translations for datatable
    const paginationComponentOptions = {
        rowsPerPageText: 'Rijen per pagina',
        rangeSeparatorText: 'van',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Selecteer alles'
    };    

    const getOffertes = async () => {
        try {
            const response = await api.get('/offertes/te-verwerken');
            if (response.data) {
                const arrayData = [];
                response.data.offertes.map((item, index) =>  {
                    const obj = {
                        id: item.id,
                        offerte: item.offertenummer,
                        klantnaam: item.klant.bedrijfsnaam,
                        accountnummer: item.klant.accountnummer ? item.klant.accountnummer : '',
                        divisie: item.divisie.label,
                        type: item.soort_offerte ? item.soort_offerte.naam : '',
                        vestiging: item.vestiging ? item.vestiging.naam : '',
                        datum: <Datum
                            sortDate={item.datum}
                            datum={moment(item.datum).format('DD-MM-YYYY')}
                        />,
                        acties: <Actions 
                                    id={item.id} 
                                    environment={environment} 
                                    offerte={item} 
                                    setOfferte={setOfferte}
                                    setShowStats={setShowStats}
                                    setStats={setStats}
                                    setLogs={setLogs}
                                    setOfferteVersies={setOfferteVersies}
                                    setSignedVersieIndex={setSignedVersieIndex}  
                                    medewerker={medewerker} 
                                    tooltipText={tooltipText} 
                                    updateStatusWorkflow={updateStatusWorkflow}
                                    downloadExcelLTL={downloadExcelLTL}
                                    downloadExcelFTL={downloadExcelFTL}
                                    handleDownload={handleDownload}
                                    setOfferteToChange={setOfferteToChange}
                                    setShowModalDebiteuren={setShowModalDebiteuren}
                                    setShowModalPortal={setShowModalPortal}
                                    setRevenue={setRevenue}
                                    setShowModalRevenue={setShowModalRevenue}
                                />,
                    };
                    arrayData.push(obj);
                })
                setOffertes(arrayData);
                setBijlagen(response.data.bijlagen);
                setLoadingOffertes(false);
                if (searchValue) {
                    setFilterText(searchValue);
                }
            }
        } catch (error) {}
    }

    const updateStatusWorkflow = async (changeOfferte) => {
        const log = {
            "omschrijving": updatedLog,
            "datum_tijd": new Date().toISOString()
        };
        changeOfferte.logs.unshift(log);
        var workflow = [...changeOfferte.workflow];
        if (changeOfferte.klant.nieuwe_klant) {
            var exit = false;
            workflow.map((item, index) =>  {
                if (!item.voltooid && !exit) {
                    item.voltooid = true;
                    item.info = updatedStatus;
                    item.log = moment().format('DD-MM-YYYY HH:mm') + ' door ' + medewerker.voornaam + ' ' + medewerker.achternaam;
                    exit = true;
                }
            });
        } else {
            workflow.map((item, index) =>  {
                if (index > 0) {
                    item.voltooid = true;
                    if (index !== 1) {
                        item.info = 'Overgeslagen omdat het een bestaande klant betrof';
                    } else {
                        item.info = updatedStatus;
                    }
                    item.log = moment().format('DD-MM-YYYY HH:mm') + ' door ' + medewerker.voornaam + ' ' + medewerker.achternaam;
                }
            });
            newStatus = 'afgerond';
        }
        try {
            const response = await api.post('/offertes/status/' + changeOfferte.id, {
                data: {
                    status: newStatus,
                    logs: changeOfferte.logs,
                    workflow: workflow,
                    send_mail: true
                }
            });
            if (response.data) {
                notifySuccess('Offerte bijgewerkt');
                getOffertes();
            }
        } catch (error) {
            setSavingOfferte(false);
            setShowModal(true);
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                    break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    const submitDebiteurNummer = async (event) => {
        event.preventDefault();
        try {
            const response = await api.post('/offertes/debiteur/', {
                data: {
                    klant: offerteToChange.klant.id,
                    debiteurnummer: debiteurnummer
                }
            });
            if (response.data) {
                updateStatusWorkflow(offerteToChange);
                setShowModalDebiteuren(false);
                setDebiteurnummer('');
            }
        } catch (error) {
            notifyError(error.response.data.error.message);
        }
    }

    const sendMailToClient = async (event) => {
        event.preventDefault();
        try {
            const response = await api.post('/offertes/send-welcome/' + offerteToChange.id, {
                data: {
                    username_portal: userNamePortal,
                    password_portal: passwordPortal,
                    link_portal: linkPortal,
                    bijlagen: bijlagenMail
                }
            });
            if (response.data) {
                updateStatusWorkflow(offerteToChange);
                setShowModalPortal(false);
                setUserNamePortal('');
                setPasswordPortal('');
                setLinkPortal('');
                setBijlagenMail([]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleAddManualLog = async (event, value, offerteId) => {
        setAddingLog(true);
        const logs = await addManualLog(event, value, offerteId);
        setLogs(logs);
        setAddingLog(false);
        notifySuccess('Log succesvol toegevoegd');
    };

    const changeRevenue = async (event) => {
        event.preventDefault();
        setLoaderMessage('Offerte omzetschatting wijzigen...');
        setShowModalRevenue(false);
        setSavingOfferte(true);
        try {
            const response = await api.post('/offertes/change-omzetschatting/' + offerteToChange.id, {
                data: {
                    omzetschatting: revenue
                }
            });
            if (response.data) {
                await delay(2500);
                getOffertes();
                notifySuccess('Offerte omzetschatting is gewijzigd');
                setSavingOfferte(false);
            }
        } catch (error) {
            setShowModalRevenue(true);
        }
    }

    const formatTime = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        return formattedTime;
    }

    //Check some things on page load and redirect if not authenticated
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        getOffertes();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        <SideNav page="/te-verwerken" />
        <div className="dashboard-content">
            <DashboardHeader title="Te verwerken offertes" />

            {
                loadingOffertes ?
                <>
                <Skeleton height={40} style={{marginBottom:10, marginTop:20}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                </>
                :
                <>
                <div className="table-header">
                    <input
                        type="text"
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                        placeholder="Zoeken..."
                    />
                </div>
                {
                    offertes.length > 0 ?
                    <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        paginationPerPage={100}
                        fixedHeader
                        noDataComponent={<p>Niks gevonden...</p>}
                    />
                    :
                    <p>Er zijn geen offertes gevonden.</p>
                }
                </>
            }

            <div className={'view-stats side-preview ' + (showStats ? 'show' : '')}>
                <div className="side-preview-header">
                    <h3>Statistieken</h3>
                    <Icon.XCircleFill onClick={() => setShowStats(false)} />
                </div>
                <div className="side-preview-content">
                    {
                        logs.length > 0 ?
                        <table id="logs">
                            <thead>
                                <tr>
                                    <th>Omschrijving</th>
                                    <th>Datum en tijd</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    logs.map((item, index) =>  {
                                        return  <tr key={index}>
                                                    <td>{item.omschrijving}</td>
                                                    <td>{moment(item.datum_tijd).format('DD-MM-YYYY HH:mm')}</td>
                                                </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        :
                        <p>Er zijn (nog) geen logs beschikbaar</p>
                    }
                    {
                        stats.length > 0 ?
                        <table id="stats">
                            <thead>
                                <tr>
                                    <th>Datum</th>
                                    <th>Tijdstip</th>
                                    <th>Tijd bekeken</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    stats.map((item, index) =>  {
                                        return  <tr key={index}>
                                                    <td>{moment(item.datum).format('DD-MM-YYYY')}</td>
                                                    <td>{item.tijdstip}</td>
                                                    <td>{formatTime(item.tijd_bekeken)}</td>
                                                </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        :
                        <p>Er zijn nog geen statistieken beschikbaar</p>
                    }
                    <form id="add-log" onSubmit={(e) => handleAddManualLog(e, manualLog, offerte.id)}>
                        <input type="text" value={manualLog} className="field" onChange={(e) => setManualLog(e.target.value)} placeholder="Voer hier eigen logomschrijving in..." required />
                        <div className="add-log-actions">
                            {
                                addingLog ?
                                <TailSpin
                                    height="25"
                                    width="25"
                                    color="#CB0A32"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                                :
                                null
                            }
                            <input type="submit" className="button" value="Toevoegen" disabled={addingLog} />
                        </div>
                    </form>
                    <div className="versions">
                        <p>Offerte versies</p>
                        {
                            offerteVersies.length > 0 ?
                            <ul className="version-list">
                            {
                                offerteVersies.map((item, index) =>  {
                                    return <>
                                    {/* {
                                        item.bestand ?
                                        <li key={index} onClick={() => { saveAs(environment + '' + item.bestand.url, item.bestand.name) }}><Icon.FilePdf />{'Versie ' + item.versie}{item.getekend ? <span>(getekend)</span> : <span></span>}</li>
                                        :
                                        null
                                    } */}
                                    {
                                        item.pad ?
                                        <li key={index} onClick={() => { handleDownload(offerte.offertenummer, 'leeg', item.pad) }}><Icon.FilePdf />{'Versie ' + item.versie}{item.getekend ? <span>(getekend)</span> : <span></span>}</li>
                                        :
                                        null
                                    }
                                    </>
                                })
                            }
                            </ul>
                            :
                            <p>Er zijn geen versies gevonden...</p>
                        }
                    </div>
                    {
                        offerte.bijlagen ?
                        <div className="versions">
                            <p>Bijlagen</p>
                            {
                                offerte.bijlagen.length > 0 ?
                                <ul className="version-list">
                                {
                                    offerte.bijlagen.map((item, index) =>  {
                                        return <li key={index} onClick={() => { saveAs(environment + '' + item.bestand.url, item.bestand.name) }}><Icon.FilePdf />{item.naam}</li>
                                    })
                                }
                                {
                                    offerte.soort_dieseltoeslag ?
                                    <li onClick={() => { saveAs(environment + '' + offerte.soort_dieseltoeslag.pdf.url, offerte.soort_dieseltoeslag.pdf.name) }}><Icon.FilePdf />{offerte.soort_dieseltoeslag.label}</li>
                                    :
                                    null
                                }
                                </ul>
                                :
                                <p>Er zijn geen bijlagen gevonden...</p>
                            }
                        </div>
                        :
                        null
                    }
                </div>
            </div>

            <Rodal visible={showModalDebiteuren} onClose={() => { setShowModalDebiteuren(false); }} width={500} enterAnimation="slideDown" leaveAnimation="slideUp" closeOnEsc={true}>
                <h3 style={{textAlign:'center'}}>Vul nieuwe debiteurnummer in</h3>
                <form id="change-status" className="popup-form" onSubmit={submitDebiteurNummer}>
                    <input type="number" placeholder="Debiteurnummer" value={debiteurnummer} onChange={(e) => setDebiteurnummer(e.target.value)} className="form-field" required />
                    <input type="submit" value="Indienen" className="green" />
                </form>
            </Rodal>

            <Rodal visible={showModalRevenue} onClose={() => { setShowModalRevenue(false); }} width={500} enterAnimation="slideDown" leaveAnimation="slideUp" closeOnEsc={true}>
                <h3 style={{textAlign:'center'}}>Omzetschatting wijzigen</h3>
                <form id="change-revenue" className="popup-form" onSubmit={changeRevenue}>
                    <input type="number" value={revenue} onChange={(e) => setRevenue(e.target.value)} placeholder="Omzetschatting" className="form-field" required />
                    <input type="submit" value="Opslaan" className="green" />
                </form>
            </Rodal>

            <Rodal visible={showModalPortal} onClose={() => { setShowModalPortal(false); }} width={500} enterAnimation="slideDown" leaveAnimation="slideUp" closeOnEsc={true}>
                <h3 style={{textAlign:'center'}}>Versturen accountgegevens</h3>
                    <form id="change-status" className="popup-form" onSubmit={sendMailToClient}>
                        <div className="two-column">
                            <input type="text" placeholder="Gebruikersnaam" value={userNamePortal} onChange={(e) => setUserNamePortal(e.target.value)} className="form-field" required />
                            <input type="text" placeholder="Inlogcode" value={passwordPortal} onChange={(e) => setPasswordPortal(e.target.value)} className="form-field" required />
                        </div>
                        <input type="text" placeholder="Link naar portal" value={linkPortal} onChange={(e) => setLinkPortal(e.target.value)} className="form-field" required />
                        {
                            bijlagen.length > 0 ?
                            <div className="bijlagen">
                            <p>Welke bijlagen wil je meesturen?</p>
                            {
                                bijlagen.map((item, index) =>  {
                                    return <div key={index} className="bijlage">
                                        <input type="checkbox" id={'bijlage-' + index} name={'bijlage-' + index} value={item.name} data-url={item.url} onChange={(e) => changeBijlagen(e)}/><label htmlFor={'bijlage-' + index}> {item.name}</label>
                                    </div>
                                })
                            }
                            </div>
                            :
                            null
                        }
                        
                        
                        <input type="submit" value="Opslaan en mail versturen" className="green" />
                    </form>
            </Rodal>

            <Toaster />
        </div>
        <BottomNav page="/te-verwerken" />
        {/* <img src={vsdvArtwork} className="artwork" /> */}
    </div>
    );
};
export default TeVerwerkenOffertes;
import { useEffect, useRef } from 'react';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import Lottie from "lottie-react";

//Styles
import '../../../index.css';
import '../offerte/offerte-bedankt.css';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';
import confetti from "../../../assets/animations/confetti.json";

const BedanktEnquete = () => {
    const lottieAnimation = useRef();

    useEffect(() => {
        
    }, []);

    return (
        <div id="thanks-offerte">
            <div className="content">
                <div className="container">
                    <Lottie lottieRef={lottieAnimation} loop={false} autoplay={true} animationData={confetti} className="confetti" style={{position:'absolute',zIndex: 0,top:0,left:0}} />
                    <div className="thanks-content">
                        <h1>Bedankt voor het invullen!</h1>
                        <p>De enquete is correct verstuurd. Wij danken u hartelijk voor uw feedback!</p>
                    </div>
                    
                </div>
            </div>
            {/* <img src={vsdvArtwork} className="artwork" /> */}
        </div>
      );
  
};
export default BedanktEnquete;